/* ------------------------------------------
   base.BODY
------------------------------------------ */

body {
  background-color: $white;
  text-align: center;
  margin: 0 auto;
  font-size: rem-calc(18);
  line-height: rem-calc(18);
  font-family: $ff;
}

p {
  text-transform: uppercase;
}
