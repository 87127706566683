.content {
  max-width: 550px;
  padding: 10px 30px 80px 10px;
  text-align: right;

  @include mq(750) {
    padding: 10px 100px 100px 10px;
  }
}

.content h1 {
  color: $white;
  font-size: rem-calc(52);
  font-weight: 700;
  text-transform: uppercase;
  line-height: rem-calc(62);
  margin: 0;

  span {
    font-size: rem-calc(26);
  }

  @include mq(450) {
    font-size: rem-calc(62);
    line-height: rem-calc(62);

    span {
      font-size: rem-calc(32);
    }
  }
}

.slogan {
  margin-top: -20px;
  padding-bottom: 20px;
}
