/* ------------------------------------------
   settings.VARIABLES
------------------------------------------ */

// Fonts
$ff_title: 'Orbitron', sans-serif;
$ff: 'Ubuntu Condensed', sans-serif;

// Colors
$color-primary: #ff2942;
$color-secondary: #0e64ff;

$black: #000;
$white: #fff;

// Layout
$gutter: 20px;

// Animations
$transition-in: 0.1s;
$transition-out: 0.3s;
