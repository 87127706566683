.wrap {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: flex-end;
  justify-content: flex-end;
  overflow: hidden;
  flex-direction: column;
  position: relative;
  padding: 10px;
  // background: linear-gradient(to right, #e65c00, #f9d423);
  // background: linear-gradient(to right, #ec008c, #fc6767);
  // background: linear-gradient(135deg, #5433ff, #20bdff, #a5fecb);
  background: linear-gradient(135deg, #12c2e9, #c471ed, #f64f59);
}
